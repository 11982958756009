import React, { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { context } from '../../../store'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'

const { pages } = require('../../../config/pages.json')
const currentstep = pages.find(i => i.id === 'checkout-step-payment')

const CheckoutBlockPayment = ({ lang, active, index, store, cartProvider }) => {
  const { checkout, cart, disabled } = store.cart

  const id_address_invoice = cart && +cart.id_address_invoice > 0
    ? cart.id_address_invoice
    : checkout && checkout._embedded && checkout._embedded.addresses && checkout._embedded.addresses[0]
      ? checkout._embedded.addresses[0].id_address
      : null

  const [payment, setPayment] = useState()
  const [saving, setSaving] = useState(false)
  const [accept, setAccept] = useState(false)

  const [code, setCode] = useState('')
  const [error, setError] = useState()

  const options = checkout._embedded ? checkout._embedded.payment_options : null
  const isValid = accept && payment

  const updateCode = (e) => {
    setCode(e.target.value)
    setError()
  }

  const addVoucher = async (e) => {
    if (e) e.preventDefault()

    setError()
    setSaving(true)
    const error = await cartProvider.addVoucher(cart, code)
    if (error) {
      setError(error)
    } else {
      setCode('')
    }
    setSaving(false)
  }

  const savePayment = async (e) => {
    e.preventDefault()
    setSaving(true)
    const result = await cartProvider.updatePayment(checkout, payment, 0, lang)

    if (result && result.completed && result.order && result.order.id_order && +result.order.id_order > 0) {
      navigate(`${pageURL('order-confirmation', result.order.id_lang)}?id=${result.order.id_order}`)
    } else if (result && result.payment && result.payment.success && result.payment.checkout) {
      console.warn('CheckoutBlockPayment redirecting to ...', result.payment)
      window.location.href = result.payment.checkout
    } else {
      console.error('CheckoutBlockPayment error', result)
    }
    setSaving(false)
  }

  const selectPayment = (e, id) => {
    e.preventDefault()
    setPayment(id)
  }

  return (
    <div className="content">
      <PageLink className="form-header d-flex align-items-center clickable" disabled={cart.step_giftwrapping ? false : true} to={currentstep[`path_${lang.suffix}`]}>
        <h3><span className="form-header-step">{index}</span>{l('checkout-payment', lang)}</h3>
      </PageLink>
      {active && (
        <>
          <form onSubmit={addVoucher}>
            <div className="form-content open" id="voucher">
              <div className="inputs container">
                <div className="row mt-4">
                  <div className='col-12'>
                    <p>Heb je een cadeaubon?</p>
                  </div>
                  <div className='col-12 col-md-8'>
                    <div className="field">
                      <input
                        className=""
                        disabled={disabled}
                        placeholder={l('field-vouchercode-add', lang)}
                        value={code} 
                        onChange={updateCode}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="field">
                      <button className="btn btn-medium-secondary next-btn" type="submit" disabled={disabled}>
                        <i className={`fas ${saving ? 'fa-spin fa-spinner' : 'fa-plus'}`}></i> {l('button-add-voucher', lang)}
                      </button>
                    </div>
                  </div>
                </div>

                {error ? (
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        {l('error-add-voucher', lang)}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
          <form onSubmit={savePayment}>
            <div className="form-content open" id="payment">
              <div className="inputs container">
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>{l('checkout-payment-select', lang)}</label>
                  </div>
                </div>
                <div className="row">
                  {options ? options.map(option => (
                    <div className="col-6 col-sm-4 col-md-3" key={`payment-${option.id}`}>
                      <button className={`payment_option btn ${payment === option.id ? 'btn-outline-secondary' : 'btn-light'}`} onClick={e => selectPayment(e, option.id)}>
                        <img src={option.image.svg} alt={option.description} />
                        <div>
                          <p>{option.description}</p>
                        </div>
                      </button>
                    </div>
                  )) : ''}
                </div>
                <div className="row">
                  <div className="col-12">
                    <input className="mr-1" type="checkbox" id="accept" value={1} checked={accept} onChange={e => setAccept(e.target.checked ? true : false)} />
                    <label className="d-inline" htmlFor="accept">{l('checkout-agree-to', lang)} <PageLink to={pageURL('terms', lang)}>{l('checkout-link-terms', lang)}</PageLink> {l('and', lang)} <PageLink to={pageURL('privacy', lang)}>{l('checkout-link-returnpolicy', lang)}</PageLink>.</label>
                  </div>
                </div>
                {!isValid && (
                  <div className="row my-2">
                    <div className="col-12">
                      <div className="alert alert-warning">
                        {l('checkout-payment-valid-warning', lang)}
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-md-end">
                  <button className="btn btn-medium-secondary next-btn" type="submit" disabled={disabled || !isValid}>
                    <i className={`fas ${saving ? 'fa-spin fa-spinner' : 'fa-chevron-right'}`}></i> {l('button-complete-order', lang)}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  )
}

CheckoutBlockPayment.propTypes = {
  lang: PropTypes.object,
  active: PropTypes.bool,
  index: PropTypes.number,
  store: PropTypes.object,
  cartProvider: PropTypes.object
}

export default CheckoutBlockPayment
